import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import Heading from "../../components/Heading"
import FAQGroup from "../../components/FAQGroup"

import styles from "./our-info.module.scss"

const OurInfo = ({ data, location }) => {

  const [faqToOpen, setFaqToOpen] = useState(null)

  // Check hash and scroll window to just above faq section
  useEffect(() => {
    if (typeof window !== "undefined" && location.hash !== "") {
      const faqId = location.hash.replace("#","")
      const faqEl = document.getElementById(faqId)
      if (faqEl)
        setTimeout(() => {
          window.scrollTo(0,faqEl.offsetTop-130)
          setFaqToOpen(faqId)
        },100)
    }
  }, [location.hash])

  const handleLivechat = () => {
    document.querySelector(".LPMcontainer").click()
  }

  return(
    <>
      <header className={styles.header}>
        <Heading
          tag="h1"
          level="0"
          className={styles.heading}
        >
          Contact & Support
        </Heading>
      </header>

      <section className={styles.module}>
        <Heading
          tag="h2"
          level="2"
          className={styles.subHeading}
        >
          Contact
        </Heading>

        <section className={styles.contactSection}>
          <Heading
            tag="h3"
            level="3"
          >
            Chat with Us
          </Heading>
          <ul className={styles.chatOptions}>
            <li>
              <a
                href="https://bcrw.apple.com/urn:biz:040ab1a9-29a8-4dc5-b791-3616dd7bfdfa"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.abcButton}
              >
                <span>iMessage</span>
              </a>
            </li>
            <li>
              <a
                href="sms:+13109974390"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.smsButton}
              >
                <span>SMS</span>
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/13109974390"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.whatsappButton}
              >
                <span>WhatsApp</span>
              </a>
            </li>
            <li>
              <a
                href="https://m.me/tamaramellonbrand"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.fbButton}
              >
                <span>Facebook</span>
              </a>
            </li>
            <li>
              <button
                className={styles.webButton}
                onClick={handleLivechat}
              >
                <span>Livechat</span>
              </button>
            </li>
          </ul>
        </section>

        {/*<section className={styles.contactSection}>*/}
        {/*  <Heading*/}
        {/*    tag="h3"*/}
        {/*    level="3"*/}
        {/*  >*/}
        {/*    Call Us*/}
        {/*  </Heading>*/}
        {/*  <p><a href="tel:8664195500" className={styles.link}>(866) 419-5500</a></p>*/}
        {/*  <p>Monday – Friday: 6 am – 6 pm PT</p>*/}
        {/*</section>*/}

        <section className={styles.contactSection}>
          <Heading
            tag="h3"
            level="3"
          >
            Email Us
          </Heading>
          <p>
            <a
              href="mailto:atyourservice@tamaramellon.com"
              className={styles.link}
            >
              atyourservice@tamaramellon.com
            </a>
          </p>
        </section>
      </section>

      <section className={styles.module} id="faq-content">
        <Heading
          tag="h2"
          level="2"
          className={styles.subHeading}
        >
          Support
        </Heading>
        <>
          {data.allContentfulFaqGroup.nodes.map((faqGroup, i) => (
            <FAQGroup
              key={i}
              data={faqGroup}
              groupNumber={i}
              faqToOpen={faqToOpen}
            />
          ))}
        </>
      </section>

       {/*<section className={styles.module} id="careers">*/}
       {/*  <Heading*/}
       {/*    tag="h2"*/}
       {/*    level="2"*/}
       {/*    className={styles.subHeading}*/}
       {/*  >*/}
       {/*    Work with Us*/}
       {/*  </Heading>*/}
       {/*  {data.allJob.distinct.map((department, i) => (*/}
       {/*    <section*/}
       {/*      key={i}*/}
       {/*      className={styles.workSection}*/}
       {/*    >*/}
       {/*      <Heading*/}
       {/*        tag="h3"*/}
       {/*        level="3"*/}
       {/*      >*/}
       {/*        {department}*/}
       {/*      </Heading>*/}
       {/*      {data.allJob.nodes.map((job, i) => (*/}
       {/*        <div*/}
       {/*          key={i}*/}
       {/*        >*/}
       {/*          {job.department === department &&*/}
       {/*            <p*/}
       {/*              key={job.id}*/}
       {/*            >*/}
       {/*              <a*/}
       {/*                href={job.url}*/}
       {/*                className={styles.link}*/}
       {/*                target="_blank"*/}
       {/*                rel="noopener noreferrer"*/}
       {/*              >*/}
       {/*                {job.title}*/}
       {/*              </a>*/}
       {/*            </p>*/}
       {/*          }*/}
       {/*        </div>*/}
       {/*      ))}*/}
       {/*    </section>*/}
       {/*  ))}*/}
       {/*</section>*/}
    </>
  )
}

export default OurInfo

export const faqsAndJobsQuery = graphql`
  query faqsAndJobsQuery {
    allContentfulFaqGroup(sort: {fields: createdAt}) {
      nodes {
        heading
        faqs {
          question
          answer {
            json
          }
        }
      }
    }
  }
`

// export const faqsAndJobsQuery = graphql`
//   query faqsAndJobsQuery {
//     allContentfulFaqGroup(sort: {fields: createdAt}) {
//       nodes {
//         heading
//         faqs {
//           question
//           answer {
//             json
//           }
//         }
//       }
//     }
//     allJob(sort: {fields: department}) {
//       distinct(field: department)
//       nodes {
//         title
//         url
//         department
//       }
//     }
//   }
// `
