import React, { useState, useEffect } from "react"

import Heading from "./Heading"
import RichText from "./RichText"

import styles from "./faqgroup.module.scss"


const FAQGroup = ({ data, groupNumber, faqToOpen }) => {
  return(
    <section className={styles.group} id={data.heading.toLowerCase().replace(/\s/g,"-")}>
      <Heading
        tag="h3"
        level="3"
        className={styles.heading}
      >
        {data.heading}
      </Heading>
      <ul>
        {data.faqs.map((faq, i) => (
          <FAQ
            key={i}
            data={faq}
            groupNumber={groupNumber}
            faqNumber={i}
            faqToOpen={faqToOpen}
          />
        ))}
      </ul>
    </section>  
  )
}

export default FAQGroup


const FAQ = ({ data, groupNumber, faqNumber, faqToOpen }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(faqToOpen === `faq${groupNumber}-${faqNumber}`)
  }, [faqToOpen, groupNumber, faqNumber])

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  return(
    <li className={styles.item}>
      <h4
        className={(isOpen) ? styles.questionOpen : styles.question}
        id={`faq${groupNumber}-${faqNumber}`}
      >
        <button
          onClick={handleClick}
        >
          {data.question}
        </button>
      </h4>
      <div
        className={(isOpen) ? styles.answerOpen : styles.answer}
      >
        <RichText
          json={data.answer.json}
        />
      </div>
    </li>
  )
}
